import { useCallback, useMemo } from 'react';
import { partition } from 'lodash-es';
import { useSelector } from 'react-redux';
import { useHandleTicketLock, useTicketLockStatus } from 'remote-state/ticketServiceHooks';
import { useRbTexts } from 'remote-state/applicationHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { selectActiveUser } from 'store/userSlice';
import Section from 'common/components/section';
import TemplateFieldsGrid from 'features/srPanel/templateFieldsGrid';
import IntegrationSection from 'features/IntegrationSection';
import { isIntegrationSection } from 'features/IntegrationSection/integrations.utils';
import { getFieldsMap } from 'common/utils/fieldUtils';
import useAnalytics from 'common/utils/hooks/useAnalytics';
import { TRACK_EVENTS } from 'constants/trackEvents';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { IntegrationTypes } from 'features/IntegrationSection/IntegrationSection.consts';
import { useAuditLog } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import { useJiraStatus } from 'features/IntegrationSection/components/jira/useJiraData';

const SrSection = (props) => {
  const { section, srId, handleDirtyFields, handleSaveSR, sr, hasEditPermissions, initExpanded, onExpansion, isFloatingTicketPanel, onGetJiraIssueKey } = props;
  const texts = useRbTexts();
  const userAccount = useSelector(selectActiveUser);
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const { mutateAsync: lockSR } = useHandleTicketLock(srId);
  const { getTicketAuditLogs } = useAuditLog()
  const trackEvents = useAnalytics();

  const { data: isJiraConnected } = useJiraStatus();
  const { data: isJiraEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.JIRA_ENABLED,
    defaultValue: false,
  });

  const integrationType = isIntegrationSection(section);

  const allFields = useMemo(() => section?.sectionRows?.flatMap((row) => row.fields), [section?.sectionRows]);

  // Currently we only check for Jira integration. When we add more integration types - make it generic
  const [integrationFields, srFields] = (integrationType === IntegrationTypes.JIRA)
    ? partition(
      allFields,
      (field) => field?.fieldName.includes(IntegrationTypes.JIRA.toLowerCase())
    ) : [[], allFields];

  const fieldsMap = useMemo(() => getFieldsMap(allFields), [allFields]);

  const handleFieldChange = useCallback(
    async (object) => {
      await handleSaveSR(object);
      const isRichText = Object.keys(object)[0] === 'richTextFields';
      const fieldName = isRichText ? JSON.parse(object.richTextFields)[0]?.columnName : Object.keys(object)[0];
      const fieldValue = isRichText ? object.richTextFields : object[Object.keys(object)[0]];
      const field = fieldsMap[fieldName];
      if (field.customColumn) {
        trackEvents(TRACK_EVENTS.CUSTOM_COLUMN_POPULATED, {
          location: 'ticket',
          srId,
          fieldName,
          fieldValue,
        });
      }
    },
    [handleSaveSR, fieldsMap, trackEvents, srId],
  );

  const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;

  const handleTicketLock = useCallback(
    () => lockSR({ username: userAccount?.username, srId }),
    [lockSR, userAccount?.username, srId],
  );

  const handleExpansion = (value) => onExpansion(section, value);

  return (
    <Section
      section={section}
      id={`section_${section.name}`}
      sectionTitle={texts[section.name] ?? section.name}
      instructions={section.instructions}
      onExpansion={onExpansion ? handleExpansion : undefined}
      initExpanded={initExpanded}
    >
      { integrationType && isJiraEnabled && isJiraConnected ? (
        <IntegrationSection
          type={integrationType}
          fields={integrationFields}
          onFieldChange={handleFieldChange}
          handleFieldFocus={handleTicketLock}
          sr={sr}
          onGetJiraIssueKey={onGetJiraIssueKey}
        />
      ) : (
        <TemplateFieldsGrid
          isTicketPage
          fields={srFields}
          sectionRows={section.sectionRows}
          handleDirtyFields={handleDirtyFields}
          handleFieldFocus={handleTicketLock}
          onFieldChange={handleFieldChange}
          isTicketLocked={isTicketLocked}
          getTicketAuditLogs={getTicketAuditLogs}
          hasEditPermissions={hasEditPermissions}
          isFloatingTicketPanel={isFloatingTicketPanel}
          sr={sr}
          isJiraIntegrationSection={false}
        />
      )}
    </Section>
  );
};

export default SrSection;
